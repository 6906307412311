<template>
  <b-container class='form text-center mt-1 pl-2 pr-2 pt-4 mb-4'>
    <div class='title clearfix'>
      <p class='font-weight-bold'>{{ $t('main-form.state-title') }}</p>
    </div>

    <div class="main-form mt-4" >
      <b-form class='b-form-padding'>
        <!-- PARENT DETAILS -->
            <!-- Personal -->
        <p class='text-left font-weight-bold mt-3'><u>1. {{ $t('main-form.parent-details.title') }}</u></p>
        <fieldset  class="fieldset-border mt-5">
          <legend class="fieldset-border" >
            {{ $t('main-form.parent-details.personal.title') }}
          </legend>
            <b-row>
              <b-col class='input-pull-id-first'
              lg='5'
              md='6'
              sm='12'
              offset-lg="0"
              offset-md="0">
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.personal.id-card')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.personal.id-card') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentIdCard'
                        size='sm'
                        type='text'
                        :disabled ='true'
                        name= "parent's ID Card">
                      </b-form-input>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='5'
                md='6'
                sm='12'
                offset-lg="0"
                offset-md="0">
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.personal.first-name')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.personal.first-name') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentFName'
                        size='sm'
                        type='text'
                        :disabled ='true'
                        name= "parent's First Name">
                      </b-form-input>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='5'
                md='6'
                sm='12'
                offset-lg="1"
                offset-md="0">
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.personal.surname')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.personal.surname') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentSurname'
                        size='sm'
                        type='text'
                        :disabled ='true'
                        name= "parent's Surname">
                      </b-form-input>
                </b-input-group>
              </b-form-group>
              </b-col>
            </b-row>
        </fieldset>
            <!-- Contact -->
        <fieldset id="contact" class="fieldset-border scrollable" :class="{'red-box-shadow': resubmissionAction === 'modify'}">
          <legend class="fieldset-border" >
            {{ $t('main-form.parent-details.contact.title') }}
          </legend>
          <b-row>
              <b-col
                lg='5'
                md='6'
                sm='12'
                offset-lg="0"
                offset-md="0">
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.contact.phone')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      {{ $t('main-form.parent-details.contact.phone') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentPhone'
                        :disabled="isDisabledAndNotModify"
                        @blur="validatePhone('Phone')"
                        size='sm'
                        type='text'
                        name="parent's Phone"
                        :class="{ 'is-invalid': checkForValidPhone && parentPhone }"
                      />
                      <div v-show="checkForValidPhone && parentPhone" class="invalid-feedback">{{checkForValidPhone}}</div>
                </b-input-group>
              </b-form-group>
            </b-col>
              <b-col
                lg='5'
                md='6'
                sm='12'
                offset-lg="1"
                offset-md="0" >
              <b-form-group >
               <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.contact.mobile')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.contact.mobile') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentMobile'
                        size='sm'
                        type='text'
                        name= "parent's Mobile"
                        :disabled="isDisabledAndNotModify"
                        @blur="validatePhone('Mobile')"
                        :class="{ 'is-invalid': submitted && !parentMobile || checkForValidMobile  }"
                      />
                      <div v-show="checkForValidMobile" class="invalid-feedback">{{checkForValidMobile}}</div>
                      <div v-show="submitted && !parentMobile" class="invalid-feedback">{{ $t('main-form.parent-details.contact.mobile-error') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-lg="0"
                offset-md="0"
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.contact.email')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.contact.email') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentEmail'
                        size='sm'
                        name= "parent's e-mail"
                        :disabled="isDisabledAndNotModify"
                        :class="{ 'is-invalid': submitted && !validateEmailAddress(parentEmail)}"
                        >
                      </b-form-input>
                      <div v-if="submitted && !parentEmail" class="invalid-feedback">{{ $t('main-form.parent-details.contact.email-required') }}</div>
                      <div v-if="!validateEmailAddress(parentEmail) && parentEmail" class="invalid-feedback">{{ $t('main-form.parent-details.contact.email-invalid') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
              <b-col
                lg='6'
                md='12'
                sm='12'
                offset-md="0"
              >
              <b-form-group v-b-popover.hover.top="$t('main-form.parent-details.contact.confirm-email')">
                <b-input-group
                size='sm'
                >
                  <b-input-group-prepend>
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.contact.confirm-email') }}
                    </span>
                  </b-input-group-prepend>

                      <b-form-input
                        v-model.trim='parentConfirmEmail'
                        size='sm'
                        name= "parent's e-mail confirmation"
                        :disabled="isDisabledAndNotModify"
                        :class="{ 'is-invalid': submitted && (!validateEmailAddress(parentConfirmEmail) || !checkforSameEmail)}"
                        >
                      </b-form-input>
                      <div v-if="submitted && parentConfirmEmail===''" class="invalid-feedback"> {{ $t('main-form.parent-details.contact.confirm-email-required') }} </div>
                      <div v-if="!checkforSameEmail" class="invalid-feedback">{{ $t('main-form.parent-details.contact.email-match') }}</div>
                      <div v-if="!validateEmailAddress(parentConfirmEmail) && parentConfirmEmail" class="invalid-feedback">{{ $t('main-form.parent-details.contact.email-invalid') }}</div>
                </b-input-group>
              </b-form-group>
              </b-col>
          </b-row>
        </fieldset>
            <!-- Address -->
        <fieldset id='address' :class="{'fieldset-border': true, 'red-box-shadow':resubmissionAction === 'modify'}">
          <legend class="fieldset-border">
            {{ $t('main-form.parent-details.address.title') }}
          </legend>
          <b-row>
            <b-col
              lg='5'
              md='6'
              sm='12'>
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.address.town')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.address.town') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentTown'
                        @blur='searchLocalityToFit'
                        size='sm'
                        type='text'
                        name= "parent's Town"
                        :disabled="isDisabledAndNotModify"
                        :class="{ 'is-invalid': (submitted || validateAddressButton) && !parentTown }">
                      </b-form-input>
                      <div v-show="(submitted || validateAddressButton) && !parentTown" class="invalid-feedback">{{ $t('main-form.parent-details.address.town-error') }}</div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              lg='5'
              md='6'
              sm='12'
              offset-lg="1"
              offset-md="0"
              class=' mb-3'>
              <b-input-group size='sm'>
                <b-input-group-text v-b-popover.hover.top="$t('main-form.parent-details.address.localityDropdown')" class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.parent-details.address.localityDropdown') }}</b-input-group-text>
                <b-form-select
                  v-model="parentLocalitySelected"
                  :options="localityDropdown"
                  size="sm"
                  ref='locality'
                  name="parent's Locality"
                  :disabled="isDisabledAndNotModify"
                  :class="{ 'is-invalid': (submitted || validateAddressButton) && !parentLocalitySelected }">
                  <template slot="first">
                    <option :value="null" disabled>{{ $t('main-form.parent-details.address.localityDropdown-default') }}</option>
                  </template>
                </b-form-select>
                <div v-show="(submitted || validateAddressButton) && !parentLocalitySelected" class="invalid-feedback">{{ $t('main-form.parent-details.address.localityDropdown-error') }}</div>
              </b-input-group>
            </b-col>
            <b-col
              lg='5'
              md='6'
              sm='12'>
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.address.street')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.address.street') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentStreet'
                        size='sm'
                        type='text'
                        name= "parent's Street"
                        :class="{ 'is-invalid': (submitted || validateAddressButton) && !parentStreet }"
                        :disabled="isDisabledAndNotModify">
                      </b-form-input>
                      <div v-show="(submitted || validateAddressButton) && !parentStreet" class="invalid-feedback">{{ $t('main-form.parent-details.address.street-error') }}</div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              lg='5'
              md='6'
              sm='12'
              offset-lg="1"
              offset-md="0">
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.address.postal')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      {{ $t('main-form.parent-details.address.postal') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentPostal'
                        size='sm'
                        type='text'
                        name= "parent's Postal"
                        :disabled="isDisabledAndNotModify">
                      </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              lg='5'
              md='6'
              sm='12'>
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.address.house-name')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      {{ $t('main-form.parent-details.address.house-name') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentHouseName'
                        size='sm'
                        type='text'
                        name= "parent's House Name"
                        :disabled="isDisabledAndNotModify">
                      </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              lg='5'
              md='6'
              sm='12'
              offset-lg="1"
              offset-md="0">
              <b-form-group >
                <b-input-group size='sm'>
                  <b-input-group-prepend v-b-popover.hover.top="$t('main-form.parent-details.address.house-number')">
                    <span class="input-group-text font-weight-bold field-with-popover">
                      * {{ $t('main-form.parent-details.address.house-number') }}
                    </span>
                  </b-input-group-prepend>
                      <b-form-input
                        v-model='parentHouseNo'
                        size='sm'
                        type='text'
                        name= "parent's House No"
                        :disabled="isDisabledAndNotModify"
                        :class="{ 'is-invalid': (submitted || validateAddressButton) && !parentHouseNo }">
                      </b-form-input>
                      <div v-show="(submitted || validateAddressButton) && !parentHouseNo" class="invalid-feedback">{{ $t('main-form.parent-details.address.house-number-error') }}</div>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </fieldset>

      <!-- Click here -->
      <div v-if='!isVerifiedAddress'>
        <p class='font-weight-bold'>{{ $t('main-form.address-button-header') }}</p>
        <b-row>
            <b-col
                md='12'
                sm='12'>
              <b-button class='mb-4'
                :disabled='isVerifiedAddress || isDisabled'
                variant="warning"
                @click='initialValidationOfAddress'>
                {{ $t('main-form.validate-address-button') }}
              </b-button>
            </b-col>
        </b-row>
      </div>

      <!-- STUDENTS DETAILS -->
        <!-- Personal -->
      <p class='text-left font-weight-bold mt-3'><u>2. {{ $t('main-form.student-details.title') }}</u>
        <simple-line-icons
          @click.native='informationPrompt("student")'
          class='ml-2'
          style='cursor:pointer;'
          icon="question"
          size="small"
          color="royalblue" />
      </p>
      <fieldset class="fieldset-border">
        <legend class="fieldset-border">
          {{ $t('main-form.student-details.personal.title') }}
        </legend>
        <b-row>
          <b-col
            class='input-pull-id-first'
            lg='5'
            md='6'
            sm='12'>
          <b-form-group >
            <b-input-group
              size='sm'>
              <b-input-group-prepend v-b-popover.hover.top="$t('main-form.student-details.personal.id-card')">
                <span class="input-group-text font-weight-bold field-with-popover">
                  * {{ $t('main-form.student-details.personal.id-card') }}
                </span>
              </b-input-group-prepend>
                  <b-form-input
                    v-model='studentIdCard'
                    size='sm'
                    type='text'
                    name= "student's ID Card"
                    :disabled ='true'>
                  </b-form-input>
            </b-input-group>
          </b-form-group>
          </b-col>
          <b-col
            lg='5'
            md='6'
            sm='12'
            offset-lg="0"
            offset-md="0">
          <b-form-group >
            <b-input-group size='sm'>
              <b-input-group-prepend v-b-popover.hover.top="$t('main-form.student-details.personal.first-name')">
                <span class="input-group-text font-weight-bold field-with-popover">
                  * {{ $t('main-form.student-details.personal.first-name') }}
                </span>
              </b-input-group-prepend>
                  <b-form-input
                    v-model='studentFName'
                    size='sm'
                    type='text'
                    name= "student's First Name"
                    :disabled ='true'>
                  </b-form-input>
            </b-input-group>
          </b-form-group>
          </b-col>
          <b-col
            lg='5'
            md='6'
            sm='12'
            offset-lg="1"
            offset-md="0">
          <b-form-group >
            <b-input-group size='sm'>
              <b-input-group-prepend v-b-popover.hover.top="$t('main-form.student-details.personal.surname')">
                <span class="input-group-text font-weight-bold field-with-popover">
                  * {{ $t('main-form.student-details.personal.surname') }}
                </span>
              </b-input-group-prepend>
                  <b-form-input
                    v-model='studentSurname'
                    size='sm'
                    type='text'
                    name= "student's Surname"
                    :disabled ='true'>
                  </b-form-input>
            </b-input-group>
          </b-form-group>
          </b-col>
        </b-row>
      </fieldset>

        <!-- TRANSPORT DETAILS -->
        <p class='text-left font-weight-bold mt-3 transport-details-margin'><u>3. {{ $t('main-form.transport-details.title') }}</u>
          <simple-line-icons
            @click.native='informationPrompt("transport")'
            class='ml-2'
            style='cursor:pointer;'
            icon="question"
            size="small"
            color="royalblue" />
        </p>
        <fieldset :class="{'fieldset-border': true, 'red-box-shadow':resubmissionAction === 'modify'}">
          <legend class="fieldset-border" >
            {{ $t('main-form.transport-details.school-pickup.title') }}
          </legend>
          <b-row>
          <b-col
            v-if='resubmissionAction === "modify" || outOfLocality === true'
            lg='5'
            md='6'
            sm='12'>
            <b-form-checkbox
              class='mb-3 font-weight-bold text-align-left'
              v-model='outOfLocality'
              size='md'
              :disabled='isDisabledAndNotModify || !isVerifiedAddress'>
              {{ $t('main-form.transport-details.school-pickup.outOfLocality-checkbox') }}
            </b-form-checkbox>
          </b-col>
          <b-col
            lg='5'
            md=6
            sm=12
            :offset-lg="(resubmissionAction === 'modify' || outOfLocality === true) ? 1 : 0"
            offset-md="0"
            class='mb-3'>
            <b-input-group
              size='sm'
              class='mb-3'>
              <b-input-group-text v-b-popover.hover.top="$t('main-form.transport-details.school-pickup.scholastic-year')" class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.transport-details.school-pickup.scholastic-year') }}</b-input-group-text>
              <b-form-select
                v-model="scholasticYearSelected"
                :options="scholasticYearOptions"
                @change="selectStudentYear"
                size="sm"
                name='Scholastic Year'
                ref='studentYear'
                :disabled='true'
                :class="{ 'is-invalid': submitted && !scholasticYearSelected }">
                <template slot="first">
                  <option :value="null" disabled>{{ $t('main-form.transport-details.school-pickup.scholastic-year-default-select') }}</option>
                </template>
              </b-form-select>
              <div v-show="submitted && !scholasticYearSelected" class="invalid-feedback">{{ $t('main-form.transport-details.school-pickup.scholastic-year-error') }}</div>
            </b-input-group>
          </b-col>
          <b-col
            lg='5'
            md=6
            sm=12
            :offset-lg="(resubmissionAction === 'modify' || outOfLocality === true) ? 0 : 1"
            class='mb-3'>
            <b-input-group
              size='sm'
              class='mb-3'>
              <b-input-group-text v-b-popover.hover.top="$t('main-form.transport-details.school-pickup.school-name.title')" class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.transport-details.school-pickup.school-name.title') }}</b-input-group-text>
              <div :class="{'form-multiselect':true, 'multiselect-isInvalid': !schoolNameSelect}">
              <multiselect
               class='multiselect-comp'
               ref="multiselect"
               v-model="schoolNameSelect"
               :options="schoolNameOptions"
               :placeholder="$t('main-form.transport-details.school-pickup.school-name.default-select')"
               selectLabel=''
               @select='onSchoolSelected'
               selectedLabel=''
               deselectLabel=''
               label="text"
               track-by="text"
               :disabled='(isDisabledAndNotModify || !isVerifiedAddress) && (!enableSchoolField)'>
               </multiselect>
            </div>
            <div v-show="!schoolNameSelect" class="multiselect-invalid-feddback">{{ $t('main-form.transport-details.school-pickup.school-name.error') }}</div>
            </b-input-group>
          </b-col>
          <b-col
          v-if='!hasNoBusStopsException'
            :offset-lg="(resubmissionAction === 'modify' || outOfLocality === true) ? 1 : 0"
            offset-md="0"
            lg='5'
            md='6'
            sm='12'>
            <b-input-group
              size='sm'
              class='mb-3'>
              <b-input-group-text v-b-popover.hover.top="$t('main-form.transport-details.school-pickup.pickup-point.title')" class='font-weight-bold field-with-popover' slot="prepend">* {{ $t('main-form.transport-details.school-pickup.pickup-point.title') }}</b-input-group-text>
              <div :class="{'form-multiselect':true, 'multiselect-isInvalid': !pickupPointSelected}">
              <multiselect
               class='multiselect-comp'
               v-model="pickupPointSelected"
               :options="pickupPointOptions"
               :placeholder="$t('main-form.transport-details.school-pickup.pickup-point.default-select')"
               selectLabel=''
               selectedLabel=''
               deselectLabel=''
               label="text"
               track-by="text"
               :disabled='(isDisabledAndNotModify || !isVerifiedAddress) && (!enableSchoolField)'>
               </multiselect>
            </div>
            <div v-show="!pickupPointSelected" class="multiselect-invalid-feddback">{{ $t('main-form.transport-details.school-pickup.pickup-point.error') }}</div>
            </b-input-group>
          </b-col>
         </b-row>
        </fieldset>

        <!-- Additional Information -->
        <fieldset :class="{'fieldset-border': true, 'red-box-shadow':resubmissionAction === 'modify'}">
          <legend class='fieldset-border'>
            {{ $t('main-form.transport-details.additional.title') }}
          </legend>
          <b-row>
            <b-col
              md='5'
              sm='12'
              offset-lg="2"
              offset-md="1">
              <b-form-checkbox
                class='mb-3 font-weight-bold text-align-left'
                v-model='wheelchair'
                :disabled ='resubmissionAction !== "modify"'
                size='md'>
                {{ $t('main-form.transport-details.additional.wheelchair') }}
              </b-form-checkbox>
            </b-col>
            <b-col
              md='5'
              sm='12'>
              <b-form-checkbox
                class='mb-3 font-weight-bold text-align-left'
                v-model='climbHighSteps'
                :disabled ='resubmissionAction !== "modify"'
                size='md'
                style='white-space:nowrap;'>
                {{ $t('main-form.transport-details.additional.climb-high-steps') }}
              </b-form-checkbox>
            </b-col>
            <!-- <b-col
              v-if='isInYearRange("myJourney")'
              md='4'
              sm='12'
              offset-lg="2"
              offset-md="1">
              <b-form-checkbox
                class='mb-3 font-weight-bold text-align-left'
                v-model='myJourney'
                size='md'
                :disabled='isDisabled || !isVerifiedAddress'>
                {{ $t('main-form.transport-details.additional.myJourney') }}
              </b-form-checkbox>
            </b-col>
            <b-col
              v-if='isInYearRange("scdp")'
              md='4'
              sm='12'
              :offset-lg="currentScholasticYearLevelInfo.schoolLevelId === 11 ? 0 : 2"
              :offset-md="currentScholasticYearLevelInfo.schoolLevelId === 11 ? 0 : 1">
              <b-form-checkbox
                class='mb-3 font-weight-bold text-align-left'
                v-model='scdp'
                size='md'
                style='white-space:nowrap;'
                :disabled='isDisabled || !isVerifiedAddress'>
                {{ $t('main-form.transport-details.additional.scdp') }}
              </b-form-checkbox>
            </b-col> -->
            <!-- <b-col
              lg='8'
              md='10'
              sm='12'
              offset-lg="2"
              offset-md="1"
              class='mb-3'>
              <b-form-textarea
                v-model='userAdditionalNotes'
                :disabled ='true'
                id="textarea"
                :placeholder="$t('main-form.transport-details.additional.notes-placeholder')"
                rows="5">
              </b-form-textarea>
            </b-col> -->
          </b-row>
        </fieldset>

         <!-- Resubmission Action Buttons -->
         <p class='text-left font-weight-bold mt-3 transport-details-margin'><u>4. {{ $t('resubmission-form.title') }}</u></p>
         <fieldset class='fieldset-border'>
          <legend class='fieldset-border'>
          </legend>
          <div class='box-wrapper padding-top-15'>
            <div class="main-form">
              <ul style='list-style-type: circle;'>
                <li class='text-left'>
                  <span style='color:green;font-weight:bold'>{{ $t('resubmission-form.confirm').toUpperCase() }} - </span>
                  <span style='color:green'>{{ $t('resubmission-form.confirm-info-text') }}</span></li>
                <li class='text-left'>
                  <span style='color:orange;font-weight:bold'>{{ $t('resubmission-form.modify').toUpperCase() }} - </span>
                  <span style='color:orange'>{{ $t('resubmission-form.modify-info-text') }}</span>
                </li>
              </ul>
            </div>
          </div>
            <b-row>
              <b-col
                lg='5'
                md=6
                sm=12
                offset-lg="3"
                offset-md="3">
                <b-form-group >
                  <b-form-radio-group
                    style='font-weight:bold;text-transform:uppercase'
                    v-model="resubmissionSelected"
                    :options="resubmissionOptions"
                    @change="selectResubmissionAction"
                    name="Resbumission Action"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
        </fieldset>

      <!-- Consent & Data Protection Notice -->
      <ConsentAndDataProtection
        :termsOfUse='termsOfUse'
        :submitted='submitted'
        :visibleProcessing="!!receiveNotifications"
        sectionNumber='5'>
        <template v-slot:termsOfUse>
          <p style='font-weight:bold' class="invalid-feedback-custom">{{ $t('main-form.consent-data.accept-terms-info') }}</p>
          <b-form-checkbox
            :disabled='resubmissionAction === null'
            class='mt-3 font-weight-bold'
            v-model='termsOfUse'
            name='terms of use'>
            <p style='font-size:0.9rem;'>{{ $t('main-form.consent-data.accept-terms') }} *</p>
          </b-form-checkbox>
        </template>
        <template v-slot:submitButton>
          <b-button
            type='button'
            class='mb-4 mt-3'
            variant="primary"
            :disabled='resubmissionAction === null'
            @click='handleSubmit'>
            {{ $t('submit-button') }}
          </b-button>
        </template>
      </ConsentAndDataProtection>

      <!-- Resubmission Action Buttons -->
      <!-- <b-row>
          <b-col
            lg='5'
            md=6
            sm=12
            offset-lg="3"
            offset-md="3">
            <b-input-group
              size='sm'
              class='mb-3'>
              <b-input-group-text class='font-weight-bold' slot="prepend">{{ $t('resubmission-form.selectAction') }} *</b-input-group-text>
              <b-form-select
                v-model="resubmissionSelected"
                :options="resubmissionOptions"
                @change="selectResubmissionAction"
                size="sm"
                name='Resbumission Action'>
                <template slot="first">
                  <option :value="null" disabled>{{ $t('main-form.transport-details.school-pickup.scholastic-year-default-select') }}</option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-col>
      </b-row> -->

      <!-- MODAL TO CONFIRM ADDRESS -->
      <b-modal ref="confirm_address_modal" centered  :title="$t('swal.main-form.choose-address')">
          <p v-for='(address,index) in geoResults' :key='index' @click='validateAddress(index)' class="text-left mb-2 pl-4 confirm-address-text">{{address.formatted_address}}</p>
        <div slot="modal-footer" class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="closeModal">
          {{ $t('swal.cancel-button') }}
        </b-button>
      </div>
      </b-modal>
    </b-form>
    </div>
  </b-container>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import storage from '@/storage'
import { successPrompt, errorPrompt, infoPrompt, confirmPrompt } from '@/prompts'
import common from '@/mixins/common'
import { validateEmailAddress, printeID, generateRandomUUID } from '@/formMethods'
import Multiselect from 'vue-multiselect'
import ConsentAndDataProtection from '@/components/FormMarkup/ConsentAndDataProtection'
const NUMBER_REGEX = /(^[0-9+]+$)/
const PARENT_ADDRESS_FIELD = ['Town', 'Street', 'HouseNo', 'LocalitySelected']

export default {
  name: 'resubmission-form',
  mixins: [common],
  components: {
    Multiselect,
    ConsentAndDataProtection
  },
  data () {
    return {
      value: { name: 'No Selection' },
      addressGeo: '',
      geoResults: [],
      api: null,
      lat: null,
      lng: null,
      userMarker: [{
        position: {
          lat: null,
          lng: null
        }
      }],
      schoolMarker: [],
      schoolBusStops: [],

      parentIdCard: '',
      parentFName: '',
      parentSurname: '',
      parentPhone: '',
      parentMobile: '',
      checkForValidMobile: '',
      checkForValidPhone: '',
      parentEmail: '',
      parentConfirmEmail: '',
      parentTown: '',
      parentStreet: '',
      parentPostal: '',
      parentHouseName: '',
      parentHouseNo: '',
      parentLocalitySelected: null,
      localityDropdown: [],
      localityValue: '', // the text of localityDropdown (is changing via getSelectValues method)
      studentId: null,
      studentIdCard: '',
      studentFName: '',
      studentSurname: '',
      pickupPointSelected: null, // the value of pickupPointOptions
      pickupPointOptions: [],
      scholasticYearSelected: null, // the value of scholasticYearOptions
      scholasticYearValue: '', // the text of scholasticYearOptions (is changing via setTextFromPointsSelected method)
      scholasticYearOptions: [],
      schoolNameSelect: null, // the value of schoolNameOptions
      schoolNameOptions: [],
      isNationalSchool: false,
      isFormValid: false, // checks if all form fields passed the validation
      submitted: false, // set true on the 1st submit click (to enable potential validation errors)
      wheelchair: false,
      climbHighSteps: false,
      receiveNotifications: null,
      myJourney: false,
      scdp: false,
      userAdditionalNotes: '',
      termsOfUse: false,
      isVerifiedAddress: true, // set to true after a successful validation of address
      validateAddressButton: false, // set true on the 1st validation of address click (to enable potential validation errors)
      hasNoBusStopsException: false,
      noBusStopsNotInExceptions: false,
      outOfLocality: false,
      resubmissionSelected: null,
      ignoreTransportChanges: false,
      nextYear: null,
      enableSchoolField: false,
      resubmissionOptions: [
        { value: 'confirm', text: this.$t('resubmission-form.confirm') },
        { value: 'modify', text: this.$t('resubmission-form.modify') }
      ],
      uniqueUUID: null
    }
  },
  methods: {
    ...mapActions({
      submitForm: 'submitResubmissionForm',
      rejectResubmissionForm: 'rejectResubmissionForm'
    }),
    ...mapMutations({
      resetUser: 'RESET_RESUBMISSION_INFO',
      setResubmissionAction: 'SET_RESUBMISSION_ACTION'
    }),
    validateEmailAddress,
    initialLoad () {
      const confirmOptions = {
        html: this.$i18n.t('swal.resubmission-form.selectResubmissionAction'),
        customClass: {
          confirmButton: 'btn btn-primary initial-load-buttons',
          cancelButton: 'btn btn-danger initial-load-buttons'
        },
        buttonsStyling: false,
        confirmButtonText: this.$i18n.t('swal.proceed-with-application-button'),
        cancelButtonText: this.$i18n.t('resubmission-form.reject')
      }
      confirmPrompt(confirmOptions)
        .then(() => {
          this.selectResubmissionAction('confirm')
          this.resubmissionSelected = 'confirm'
          this.fillResubmissionInfo()
          this.$gmapApiPromiseLazy().then(api => {
            this.api = api
          })
        })
        .catch(() => {
          this.showRejectionPrompt()
        })
    },
    noHigherSchoolLevelPromt (currentScholasticYear) {
      this.fillStudentYears(currentScholasticYear, 'notAvailableNextYear')
      this.selectStudentYear('actionChanged')
      this.onSchoolSelected()
      const errorOptions = {
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        confirmButtonText: this.$i18n.t('home.logout')
      }
      this.$nextTick(() => {
        errorPrompt(this.$i18n.t('swal.resubmission-form.noHigherLevel'), errorOptions)
          .then(() => {
            storage.remove.resubmissionToken()
            this.resetUser()
            this.$router.replace({ name: 'home' })
          })
      })
    },
    fillResubmissionInfo () {
      const { user, child } = this.baseData
      this.parentIdCard = user.userCardId
      this.parentFName = user.userFirstName
      this.parentSurname = user.userLastName
      this.parentPhone = user.userTel
      this.parentMobile = user.userMobile
      this.parentEmail = user.userEmail
      this.parentConfirmEmail = user.userEmail
      this.parentTown = user.userLocality
      this.parentStreet = user.userStreet
      this.parentPostal = user.userPostalCode
      this.parentHouseName = user.userHouseName
      this.parentHouseNo = user.userDoorNumber
      this.studentIdCard = child.childCardId
      this.studentFName = child.childFirstName
      this.studentSurname = child.childLastName
      this.$set(this.userMarker[0].position, 'lat', user.userLat)
      this.$set(this.userMarker[0].position, 'lng', user.userLon)
      this.fillLocalities()
      this.fillTransportDetails()
    },
    fillLocalities () {
      const selectedLocality = this.baseData.locality
      this.supplementary.localities.forEach((locality) => {
        this.localityDropdown.push({ value: locality.localityId, text: locality.localityName })
        if (selectedLocality.localityId === locality.localityId) {
          this.parentLocalitySelected = locality.localityId
        }
      })
    },
    fillTransportDetails () {
      const { school, busStop, additional } = this.baseData
      this.outOfLocality = additional.isSchoolOutOfLocality
      this.wheelchair = additional.wheelchair
      this.climbHighSteps = additional.cannotClimbStairs
      this.myJourney = additional.myJourney
      this.scdp = additional.scdp
      this.userAdditionalNotes = additional.notes
      const currentScholasticYear = this.supplementary.schoolLevels.find(level => {
        return level.schoolLevelId === this.schoolLevelId
      })
      if (currentScholasticYear.schoolLevelIsFinal) {
        this.nextYear = currentScholasticYear
        // return this.noHigherSchoolLevelPromt(currentScholasticYear)
      } else {
        this.nextYear = this.supplementary.schoolLevels.find(el => {
          return currentScholasticYear.schoolLevelId + 1 === el.schoolLevelId
        })
      }
      if (this.resubmissionAction === 'confirm' || this.resubmissionAction === 'modify') {
        const infoOptions = {
          confirmButtonText: this.$i18n.t('swal.ok-button')
        }
        if (this.resubmissionAction === 'modify') {
          const currentSchoolLevels = this.supplementary.schools.find(supplSchool => supplSchool.schoolId === school.schoolId).schoolLevels
          if (currentSchoolLevels && currentSchoolLevels.includes(this.nextYear.schoolLevelId)) { // if current school has the next year
            this.fillStudentYears(currentScholasticYear)
            this.selectStudentYear('actionChanged')
            this.onSchoolSelected()
          } else {
            this.fillStudentYearsException('modify', currentScholasticYear, infoOptions)
          }
        } else { // confirm
          const currentSchoolLevels = this.supplementary.schools.find(supplSchool => supplSchool.schoolId === school.schoolId).schoolLevels
          if (currentSchoolLevels && currentSchoolLevels.includes(this.nextYear.schoolLevelId)) { // if current school has the next year
            this.fillStudentYears(currentScholasticYear)
            this.selectStudentYear('actionChanged')
            this.onSchoolSelected()
          } else {
            this.fillStudentYearsException('confirm', currentScholasticYear, infoOptions)
          }
        }
      } else { // initial data before proceed with application
        this.scholasticYearOptions.push({ value: currentScholasticYear.schoolLevelLabel, text: currentScholasticYear.schoolLevelLabel, levels: currentScholasticYear.schoolLevelId })
        this.schoolNameOptions.push({ value: school.schoolId, text: school.schoolName })
        this.schoolMarker.push(this.schoolMarkerOptions(school))
        if (busStop && busStop.hasOwnProperty('busStopId')) {
          this.pickupPointOptions.push({ value: busStop.busStopId, text: busStop.busStopName })
          this.schoolBusStops.push(this.schoolBusStopsMarkerOptions(busStop))
        }
        this.$nextTick(() => {
          this.scholasticYearSelected = currentScholasticYear.schoolLevelLabel
          this.schoolNameSelect = { value: school.schoolId, text: school.schoolName }
          if (busStop && busStop.hasOwnProperty('busStopId')) {
            this.pickupPointSelected = { value: busStop.busStopId, text: busStop.busStopName }
          } else {
            this.hasNoBusStopsException = true
          }
        })
      }
    },
    validatePhone (number) {
      let parentPhoneLength = 0
      if (this[`parent${number}`]) {
        this[`parent${number}`] = this[`parent${number}`].replace(/\s/g, '')
        const checkParentPhone = this[`parent${number}`].startsWith('+')
        if (checkParentPhone) {
          this[`parent${number}`] = '+' + this[`parent${number}`].replace(/[^0-9]/ig, '')
          parentPhoneLength = this[`parent${number}`].length - 1
        } else {
          this[`parent${number}`] = this[`parent${number}`].replace(/[^0-9]/ig, '')
          parentPhoneLength = this[`parent${number}`].length
        }
      } else {
        if (number === 'Phone') { // delete error if no parentPhone, coz its not mandatory
          this.checkForValidPhone = ''
          return
        }
        return
      }
      if (number === 'Phone') { // if number exists & blurring parentPhone, validate it
        if ((!this.parentPhone.match(NUMBER_REGEX) || parentPhoneLength > 20 || parentPhoneLength < 5)) {
          this.checkForValidPhone = this.$i18n.t('main-form.parent-details.contact.error-phone-valid')
        } else {
          this.checkForValidPhone = ''
        }
      } else { // if number exists & blurring parentMobile, validate it
        if (this.parentMobile.match(NUMBER_REGEX) && parentPhoneLength < 20 && parentPhoneLength > 4) {
          const malteseNumbers = [ '79', '99', '77', '78', '98' ]
          const countryCode = ['+', '00']
          const checkForCountryCode = countryCode.some((number) => {
            return this.parentMobile.startsWith(number)
          })
          const isMaltseNumber = malteseNumbers.some((number) => {
            return this.parentMobile.startsWith(number)
          })
          this.checkForValidMobile = ''
          if (!checkForCountryCode && isMaltseNumber) {
            this.parentMobile = `00356${this.parentMobile}`
          } else if (!checkForCountryCode && !isMaltseNumber) {
            this.checkForValidMobile = this.$i18n.t('main-form.parent-details.contact.error-country-code')
          }
        } else {
          this.checkForValidMobile = this.$i18n.t('main-form.parent-details.contact.error-mobile-valid')
        }
      }
    },
    fillStudentYearsException (action, currentScholasticYear, infoOptions) {
      const { school, busStop } = this.baseData
      const nextSchoolIds = this.supplementary.nextSchoolIds
      if (nextSchoolIds.length === 0) {
        if (action === 'confirm') {
          this.fillStudentYears(currentScholasticYear, 'notAvailableNextYear')
          this.selectStudentYear('actionChanged')
          this.onSchoolSelected()
          this.$nextTick(() => infoPrompt(this.$i18n.t('swal.resubmission-form.levelNotAvailabelAccept', { level: this.nextYear.schoolLevelLabel, schoolName: school.schoolName }), infoOptions))
        } else {
          this.fillStudentYears(currentScholasticYear)
          this.selectStudentYear()
          // this.$nextTick(() => infoPrompt(this.$i18n.t('swal.resubmission-form.levelNotAvailabelModify', { level: this.nextYear.schoolLevelLabel, schoolName: school.schoolName }), infoOptions))
        }
      } else if (nextSchoolIds.length === 1) {
        const newSchool = this.supplementary.schools.find(school => school.schoolId === nextSchoolIds[0])
        const busStopExistsInNewSchool = newSchool.busStops.some(bus => {
          if (busStop && busStop.hasOwnProperty('busStopId')) {
            return bus.busStopId === busStop.busStopId
          }
        })
        if (action === 'confirm') {
          this.enableSchoolField = true
          this.fillStudentYears(currentScholasticYear, 'notAvailableNextYear', 'confirmException')
          this.schoolNameOptions = [{ value: newSchool.schoolId, text: newSchool.schoolName }]
          this.schoolNameSelect = { value: newSchool.schoolId, text: newSchool.schoolName }
          this.onSchoolSelected({ value: newSchool.schoolId })
        } else {
          this.fillStudentYears(currentScholasticYear)
          this.selectStudentYear()
          this.onSchoolSelected({ value: newSchool.schoolId })
          this.schoolNameSelect = { value: newSchool.schoolId, text: newSchool.schoolName }
        }
        if (busStopExistsInNewSchool) {
          this.$nextTick(() => action === 'confirm' ? infoPrompt(this.$i18n.t('swal.resubmission-form.levelNotAvailabelModify', { level: this.nextYear.schoolLevelLabel, schoolName: school.schoolName }), infoOptions) : null)
          this.pickupPointSelected = { value: busStop.busStopId, text: busStop.busStopName }
        } else {
          this.$nextTick(() => action === 'confirm' ? infoPrompt(this.$i18n.t('swal.resubmission-form.previous-busStop-notExist', { schoolName: newSchool.schoolName }), infoOptions) : null)
        }
      } else {
        this.enableSchoolField = true
        if (action === 'confirm') {
          this.$nextTick(() => infoPrompt(this.$i18n.t('swal.resubmission-form.levelNotAvailabelModify', { level: this.nextYear.schoolLevelLabel, schoolName: school.schoolName }), infoOptions))
          this.fillStudentYears(currentScholasticYear, 'notAvailableNextYear', 'confirmException')
          this.schoolNameSelect = null
          this.schoolMarker = []
          this.schoolNameOptions = []
          this.pickupPointSelected = null
          this.pickupPointOptions = []
          nextSchoolIds.forEach((nextSchoolId) => {
            const foundSchool = this.supplementary.schools.find((id) => {
              return id.schoolId === nextSchoolId
            })
            this.schoolNameOptions.push({ value: foundSchool.schoolId, text: foundSchool.schoolName })
          })
        } else {
          this.fillStudentYears(currentScholasticYear)
          this.selectStudentYear()
          // this.$nextTick(() => infoPrompt(this.$i18n.t('swal.resubmission-form.levelNotAvailabelModify', { level: this.nextYear.schoolLevelLabel, schoolName: school.schoolName }), infoOptions))
        }
      }
    },
    fillStudentYears (currentScholasticYear, notAvailableNextYear, confirmException) {
      const { school } = this.baseData
      const currentSchoolLevels = this.supplementary.schools.find(supplSchool => supplSchool.schoolId === school.schoolId).schoolLevels
      if (confirmException) {
        this.scholasticYearOptions.push({ value: this.nextYear.schoolLevelLabel, text: this.nextYear.schoolLevelLabel, levels: this.nextYear.schoolLevelId })
        this.scholasticYearSelected = this.nextYear.schoolLevelLabel
      } else {
        if (notAvailableNextYear) {
          this.scholasticYearOptions.push({ value: currentScholasticYear.schoolLevelLabel, text: currentScholasticYear.schoolLevelLabel, levels: currentScholasticYear.schoolLevelId })
          this.scholasticYearSelected = currentScholasticYear.schoolLevelLabel
        } else {
          this.scholasticYearOptions = this.supplementary.schoolLevels.reduce((acc, curr) => {
            if (this.resubmissionAction === 'confirm') {
              if (currentSchoolLevels.includes(curr.schoolLevelId) && curr.schoolLevelId >= currentScholasticYear.schoolLevelId) {
                acc.push({ value: curr.schoolLevelLabel, text: curr.schoolLevelLabel, levels: curr.schoolLevelId })
              }
              return acc
            } else {
              if (curr.schoolLevelId >= currentScholasticYear.schoolLevelId) {
                acc.push({ value: curr.schoolLevelLabel, text: curr.schoolLevelLabel, levels: curr.schoolLevelId })
              }
              return acc
            }
          }, [])
          this.scholasticYearSelected = this.nextYear.schoolLevelLabel
        }
      }
    },
    selectStudentYear (val) {
      if (!this.isVerifiedAddress) return
      const { schools } = this.supplementary
      if (val !== 'actionChanged' && this.resubmissionAction === 'modify') {
        this.schoolNameSelect = null
        this.schoolMarker = []
        this.schoolNameOptions = []
        this.pickupPointSelected = null
        this.pickupPointOptions = []
      }
      let selectedStudentYear = this.scholasticYearOptions.find((input) => {
        return input.value === this.scholasticYearSelected
      })
      if (!this.outOfLocality) {
        schools.forEach((school) => {
          let checkLocality = school.localities.find((locality) => locality.localityId === this.parentLocalitySelected)
          if (checkLocality) {
            this.appendSchools(school, selectedStudentYear)
          }
        })
      } else {
        schools.forEach((school) => {
          this.appendSchools(school, selectedStudentYear)
        })
      }
      if (val === 'actionChanged') {
        const { school } = this.baseData
        this.schoolNameSelect = { value: school.schoolId, text: school.schoolName }
      }
    },
    appendSchools (school, selectedStudentYear) {
      const hasTheSchoolLevel = school.schoolLevels.some(level => level === selectedStudentYear.levels)
      if (hasTheSchoolLevel) {
        this.schoolNameOptions.push({ value: school.schoolId, text: school.schoolName })
      }
    },
    // if school is preselected then "id" property is not passed to 'onSchoolSelected'
    onSchoolSelected (id) {
      if (id) {
        this.pickupPointOptions = []
        this.pickupPointSelected = null
        this.schoolMarker = []
        this.schoolBusStops = []
      }
      const { school, busStop } = this.baseData
      const currentSchool = id ? this.supplementary.schools.find((supplSchool) => id.value === supplSchool.schoolId) : this.supplementary.schools.find((supplSchool) => supplSchool.schoolId === school.schoolId)
      this.hasNoBusStopsException = false
      this.noBusStopsNotInExceptions = false
      this.isNationalSchool = school.schoolIsNational
      this.schoolMarker.push(this.schoolMarkerOptions(currentSchool))
      const infoOptions = {
        confirmButtonText: this.$i18n.t('swal.ok-button')
      }
      if (currentSchool.schoolIsDoorToDoor) {
        this.hasNoBusStopsException = true
        return !id && this.resubmissionAction === 'modify' ? null : this.$nextTick(() => infoPrompt(this.$i18n.t('main-form.transport-details.school-pickup.pickup-point.info-text-no-busStops-exceptions'), infoOptions))
      } else {
        if ((currentSchool.busStops === null || currentSchool.busStops.length === 0)) {
          if (!id) {
            this.pickupPointSelected = null
          }
          this.noBusStopsNotInExceptions = true
          return !id && this.resubmissionAction === 'modify' ? null : this.$nextTick(() => infoPrompt(this.$i18n.t('main-form.transport-details.school-pickup.pickup-point.info-text-no-busStops-noExceptions'), infoOptions))
        } else {
          currentSchool.busStops.forEach((busStop) => {
            this.pickupPointOptions.push({ value: busStop.busStopId, text: busStop.busStopName })
            this.schoolBusStops.push(this.schoolBusStopsMarkerOptions(busStop))
          })
          if (!id) {
            if (busStop && busStop.hasOwnProperty('busStopId')) {
              let foundBusStop = currentSchool.busStops.find(pickup => {
                return pickup.busStopId === busStop.busStopId
              })
              if (!foundBusStop) {
                this.pickupPointSelected = null
                return this.resubmissionAction === 'confirm' ? this.$nextTick(() => infoPrompt(this.$i18n.t('resubmission-form.no-busStops-select-modify'), infoOptions)) : null
              } else {
                this.pickupPointSelected = { value: busStop.busStopId, text: busStop.busStopName }
              }
            } else {
              this.pickupPointSelected = null
              return this.resubmissionAction === 'confirm' ? this.$nextTick(() => infoPrompt(this.$i18n.t('resubmission-form.no-busStops-select-modify'), infoOptions)) : null
            }
          }
        }
      }
    },
    schoolMarkerOptions (school) {
      return {
        position: {
          lat: school.schoolLat,
          lng: school.schoolLon
        },
        schoolId: school.schoolId,
        schoolCode: school.schoolCode,
        schoolName: school.schoolName,
        schoolAddress: school.schoolAddress,
        schoolZipCode: school.schoolZipCode,
        schoolIsNational: school.schoolIsNational,
        schoolBusStops: this.schoolBusStops
      }
    },
    schoolBusStopsMarkerOptions (busStop) {
      return {
        position: {
          lat: busStop.busStopLat,
          lng: busStop.busStopLon
        },
        busStopId: busStop.busStopId,
        busStopCode: busStop.busStopCode,
        busStopName: busStop.busStopName,
        busStopAddress: busStop.busStopAddress,
        busStopZipCode: busStop.busStopZipCode
      }
    },
    selectResubmissionAction (val) {
      this.ignoreTransportChanges = true
      this.setResubmissionAction(val)
      if (val === 'modify') {
        this.scrollToField('contact')
      }
    },
    closeModal () {
      this.$refs['confirm_address_modal'].hide()
    },
    confirmAddressModal () {
      this.$refs['confirm_address_modal'].show()
    },
    initialValidationOfAddress () {
      this.validateAddressButton = true
      let isEmptyField = PARENT_ADDRESS_FIELD.some((field) => this[`parent${field}`] === '' || this[`parent${field}`] === null)
      if (isEmptyField) {
        this.geoResults = []
        return this.scrollToMissingFields()
      }
      this.geoResults = []
      let postCode = this.parentPostal ? this.parentPostal : ' '
      this.addressGeo = `${this.parentTown} ${this.parentStreet} ${this.parentHouseNo} ${postCode} Malta`
      this.geocode(this.addressGeo)
        .then(result => {
          this.geoResults = result
          if (this.geoResults.length > 1) {
            return this.confirmAddressModal() // If more than 1 address returned from google,show propmt to choose the address.
          }
          this.validationSucceeded(result)
          this.selectStudentYear()
        })
        .catch(error => {
          if (error.message === 'Geocoder failed due to: ZERO_RESULTS') {
            this.prompt('error', this.$i18n.t('swal.main-form.no-address'))
          }
        })
    },

    // Geocode the address that user selected, from addresses google maps returned
    validateAddress (address) {
      this.$refs['confirm_address_modal'].hide()
      this.geocode(this.geoResults[address].formatted_address)
        .then(result => {
          this.geoResults = result
          this.validationSucceeded(result)
          this.selectStudentYear()
        })
        .catch(() => {
          this.geoResults = []
        })
    },
    validationSucceeded (result) {
      this.verifiedAddress()
      this.$set(this.userMarker[0].position, 'lat', result[0].geometry.location.lat())
      this.$set(this.userMarker[0].position, 'lng', result[0].geometry.location.lng())
    },
    geocode (addressGeo) {
      if (this.api === null) return Promise.reject(new Error('no gmaps API found'))
      return new Promise((resolve, reject) => {
        this.geocoder = new this.api.maps.Geocoder()
        const geoCoderOptions = { address: addressGeo }
        const geoCoderCallback = (result, status) => {
          if (status === 'OK') {
            resolve(result)
          } else {
            reject(new Error('Geocoder failed due to: ' + status))
          }
        }
        this.geocoder.geocode(geoCoderOptions, geoCoderCallback)
      })
    },
    handleSubmit () {
      this.submitted = true
      if (!this.checkforSameEmail ||
        !this.validateEmailAddress(this.parentEmail) ||
        !this.validateEmailAddress(this.parentConfirmEmail)) {
        return this.prompt('error')
      }
      this.mapFieldsForValidation()
      return this.isFormValid ? this.prompt('success') : this.prompt('error')
    },
    searchLocalityToFit () {
      const { localities } = this.supplementary
      if (!this.parentTown) return
      this.parentLocalitySelected = null
      localities.forEach((locality) => {
        if (this.parentTown.toLowerCase().includes(locality.localityName.toLowerCase())) {
          this.parentLocalitySelected = locality.localityId
        }
      })
    },
    mapFieldsForValidation () {
      const mapValues = [
        'parentMobile', 'parentEmail',
        'parentConfirmEmail',
        'parentTown', 'parentLocalitySelected', 'parentStreet', 'parentHouseNo', 'scholasticYearSelected', 'schoolNameSelect', 'termsOfUse'
      ]
      this.isFormValid = mapValues.every((input) => this[input])
      if (this.noBusStopsNotInExceptions) {
        const infoOptions = {
          confirmButtonText: this.$i18n.t('swal.ok-button')
        }
        this.isFormValid = false
        infoPrompt(this.$i18n.t('main-form.transport-details.school-pickup.pickup-point.info-text-no-busStops-noExceptions'), infoOptions)
      }
      if (!this.hasNoBusStopsException && !this.pickupPointSelected) {
        this.isFormValid = false
      }
    },
    informationPrompt (field) {
      const infoOptions = {
        confirmButtonText: this.$i18n.t('swal.ok-button')
      }
      return field === 'student' ? infoPrompt(this.$i18n.t('main-form.student-details.info-text'), infoOptions)
        : field === 'transport' ? infoPrompt(this.$i18n.t('main-form.transport-details.info-text'), infoOptions)
          : infoPrompt(this.$i18n.t('main-form.transport-details.pin-info'), infoOptions)
    },
    verifiedAddress () {
      const options = {
        confirmButtonText: this.$i18n.t('swal.ok-button')
      }
      this.isVerifiedAddress = true
      successPrompt(this.$i18n.t('swal.main-form.valid-address'), options)
    },
    // isInYearRange (id) {
    //   const scholasticYearLevel = this.supplementary.schoolLevels.find(yl => {
    //     return yl.schoolLevelLabel === this.scholasticYearSelected
    //   })
    //   if (scholasticYearLevel) {
    //     switch (id) {
    //       case 'myJourney':
    //         return scholasticYearLevel.schoolLevelId >= 11 && scholasticYearLevel.schoolLevelId <= 13
    //       case 'scdp':
    //         return scholasticYearLevel.schoolLevelId >= 9 && scholasticYearLevel.schoolLevelId <= 11
    //     }
    //   }
    // },
    prompt (type, message) {
      if (type === 'error') {
        const errorOptions = {
          confirmButtonText: this.$i18n.t('swal.ok-button')
        }
        return message ? errorPrompt(message, errorOptions) : this.scrollToMissingFields()
      }
      return this.showPrompt()
    },
    showRejectionPrompt () {
      const confirmOptions = {
        title: this.$i18n.t('swal.confirm-title-reject'),
        // html: `<p class='font-weight-bold'> ${this.$i18n.t('resubmission-form.rejectResubmissionForm')}<p>`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-3'
        },
        buttonsStyling: false,
        confirmButtonText: this.$i18n.t('swal.yes-button'),
        cancelButtonText: this.$i18n.t('no')
      }
      confirmPrompt(confirmOptions)
        .then(() => {
          this.setResubmissionAction('reject')
          this.rejectResubmissionForm()
            .then(() => {
              const confirmOptions = {
                confirmButtonText: this.$i18n.t('swal.ok-button')
              }
              successPrompt(this.$i18n.t('swal.resubmission-form.reject-error-200'), confirmOptions)
                .then(() => {
                  storage.remove.resubmissionToken()
                  this.resetUser()
                  this.$router.replace({ name: 'home' })
                })
            })
            .catch((error) => {
              const errorOptions = {
                confirmButtonText: this.$i18n.t('swal.ok-button')
              }
              if (error.hasOwnProperty('response') && error.hasOwnProperty(status) && error.response.status === 403) {
                errorPrompt(this.$i18n.t('swal.resubmission-form.submit-error-403-dublicate'), errorOptions)
                  .then(() => {
                    storage.remove.resubmissionToken()
                    this.resetUser()
                    this.$router.replace({ name: 'home' })
                  })
              } else {
                errorPrompt(this.$i18n.t('swal.main-form.submit-general-error'), errorOptions)
                  .then(() => {
                    storage.remove.resubmissionToken()
                    this.resetUser()
                    this.$router.replace({ name: 'home' })
                  })
              }
            })
        })
        .catch((result) => {
          this.initialLoad()
        })
    },
    showPrompt () {
      this.setTextFromPointsSelected()
      const confirmOptions = {
        title: this.$i18n.t('swal.confirm-title'),
        html: this.summaryDetails(),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-3'
        },
        buttonsStyling: false,
        confirmButtonText: this.$i18n.t('swal.yes-button'),
        cancelButtonText: this.$i18n.t('swal.cancel-button')
      }
      confirmPrompt(confirmOptions)
        .then((result) => {
          const busStation = this.schoolBusStops.find((busStop) => busStop.busStopId === this.pickupPointSelected.value)
          let busStop = null
          if (busStation) {
            const { busStopId, busStopCode, busStopName, busStopAddress, busStopZipCode, position } = busStation
            busStop = {
              busStopId,
              busStopCode,
              busStopName,
              busStopAddress,
              busStopZipCode,
              busStopLat: position.lat,
              busStopLon: position.lng
            }
          }
          const localityValue = this.getOptionFromSelect('locality', this.parentLocalitySelected)
          const { user } = this.baseData
          const submitData = {
            locality: {
              localityId: localityValue.value,
              localityName: localityValue.text
            },
            user: {
              userId: user.userId,
              userCardId: this.parentIdCard,
              userFirstName: this.parentFName,
              userLastName: this.parentSurname,
              userEmail: this.parentEmail,
              userMobile: this.parentMobile,
              userTel: this.parentPhone,
              userLocality: this.parentTown,
              userStreet: this.parentStreet,
              userPostalCode: this.parentPostal,
              userDoorNumber: this.parentHouseNo,
              userHouseName: this.parentHouseName,
              userCountry: user.userCountry,
              userPreferredLanguage: user.userPreferredLanguage,
              userLat: this.userMarker[0].position.lat,
              userLon: this.userMarker[0].position.lng
            },
            child: {
              childId: this.studentId,
              childCardId: this.studentIdCard,
              childFirstName: this.studentFName,
              childLastName: this.studentSurname
            },
            school: {
              schoolId: this.schoolMarker[0].schoolId,
              schoolCode: this.schoolMarker[0].schoolCode,
              schoolName: this.schoolMarker[0].schoolName,
              schoolIsNational: this.schoolMarker[0].schoolIsNational,
              schoolAddress: this.schoolMarker[0].schoolAddress,
              schoolZipCode: this.schoolMarker[0].schoolZipCode,
              schoolLat: this.schoolMarker[0].position.lat,
              schoolLon: this.schoolMarker[0].position.lng
            },
            busStop,
            additional: {
              studentYear: this.scholasticYearSelected,
              wheelchair: this.wheelchair,
              cannotClimbStairs: this.climbHighSteps,
              notes: this.userAdditionalNotes,
              isSchoolOutOfLocality: this.outOfLocality,
              myJourney: this.myJourney,
              scdp: this.scdp
              // myJourney: this.isInYearRange('myJourney') ? this.myJourney : false,
              // scdp: this.isInYearRange('scdp') ? this.scdp : false,
            },
            serviceUUID: this.uniqueUUID
          }
          this.submitForm(submitData)
            .then((res) => {
              const { referenceId, isEligible } = res
              const html =
               `<div id="e-id"><p class='font-weight-bold'>${this.$i18n.t('swal.main-form.success-submit')}<p>
               <p class='font-weight-bold'>${this.$i18n.t('swal.main-form.your-id')}: ${referenceId}<p>
               ${this.outOfLocality ? `<p>${this.$i18n.t('swal.main-form.out-of-locality-error')}</p>` : ''}
               ${isEligible ? '' : `<p>${this.$i18n.t('swal.main-form.submit-warning-200-distance')}</p>`}
               ${this.summaryDetails()}</div>`
              const customClass = {
                cancelButton: 'printButton'
              }
              const printOptions = {
                type: isEligible ? 'success' : 'warning',
                html,
                customClass,
                confirmButtonText: this.$i18n.t('home.logout'),
                cancelButtonText: this.$i18n.t('swal.print-button')
              }
              confirmPrompt(printOptions)
                .then(() => {
                  storage.remove.resubmissionToken()
                  this.resetUser()
                  this.$router.replace({ name: 'home' })
                })
                // eslint-disable-next-line
                .catch((err) => {
                  storage.remove.resubmissionToken()
                  this.resetUser()
                  this.$router.replace({ name: 'home' })
                  printeID()
                })
            }).catch((error) => {
              const errorOptions = {
                confirmButtonText: this.$i18n.t('swal.ok-button')
              }
              if (error.response.status === 403) {
                errorPrompt(this.$i18n.t('swal.resubmission-form.submit-error-403-dublicate'), errorOptions)
              } else if (error.response.status === 406) {
                errorPrompt(this.$i18n.t('swal.resubmission-form.reject-error-406-periodIsOver'), errorOptions)
              } else if (error.response.status === 429) {
                return errorPrompt(this.$i18n.t('swal.main-form.too-many-requests-429'), errorOptions)
              } else if (error.response.status === 412) {
                return errorPrompt(this.$i18n.t('swal.resubmission-form.reject-error-412-studentInactive'), errorOptions)
              } else {
                errorPrompt(this.$i18n.t('swal.main-form.submit-general-error'), errorOptions)
              }
            })
        })
        .catch((result) => {

        })
    },
    summaryDetails () {
      return (
        `<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.parent-details.title')}</u><p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.personal.id-card')}: <span class='font-weight-bold'> ${this.parentIdCard} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.personal.first-name')}: <span class='font-weight-bold'> ${this.parentFName} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.personal.surname')}: <span class='font-weight-bold'> ${this.parentSurname} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.town')}: <span class='font-weight-bold'> ${this.parentTown} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.localityDropdown')}: <span class='font-weight-bold'> ${this.localityValue} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.street')}: <span class='font-weight-bold'> ${this.parentStreet} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.postal')}: <span class='font-weight-bold'> ${this.parentPostal ? this.parentPostal : '-'} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.house-name')}: <span class='font-weight-bold'> ${this.parentHouseName ? this.parentHouseName : '-'} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.address.house-number')}: <span class='font-weight-bold'> ${this.parentHouseNo} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.contact.mobile')}: <span class='font-weight-bold'> ${this.parentMobile} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.parent-details.contact.email')}: <span class='font-weight-bold'> ${this.parentEmail} </span> </p>` +
        `<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.student-details.title')}</u></p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.student-details.personal.id-card')}: <span class='font-weight-bold'> ${this.studentIdCard} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.student-details.personal.first-name')}: <span class='font-weight-bold'> ${this.studentFName} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.student-details.personal.surname')}: <span class='font-weight-bold'> ${this.studentSurname} </span> </p>` +
        `<p class='text-left font-weight-bold'><u>${this.$i18n.t('main-form.transport-details.title')}</u></p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.school-pickup.scholastic-year')}: <span class='font-weight-bold'> ${this.scholasticYearValue} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.school-pickup.school-name.title')}: <span class='font-weight-bold'> ${this.schoolNameSelect.text} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.school-pickup.pickup-point.title')}: <span class='font-weight-bold'> ${this.pickupPointSelected ? this.pickupPointSelected.text : '-'} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.additional.wheelchair')}: <span class='font-weight-bold'> ${this.wheelchair ? this.$i18n.t('yes') : this.$i18n.t('no')} </span> </p>` +
        `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.additional.climb-high-steps')}: <span class='font-weight-bold'> ${this.climbHighSteps ? this.$i18n.t('yes') : this.$i18n.t('no')} </span> </p>`
        // `${this.isInYearRange('myJourney') ? `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.additional.myJourney')}: <span class='font-weight-bold'> ${this.myJourney ? this.$i18n.t('yes') : this.$i18n.t('no')} </span> </p>` : ''}` +
        // `${this.isInYearRange('scdp') ? `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.additional.scdp')}: <span class='font-weight-bold'> ${this.scdp ? this.$i18n.t('yes') : this.$i18n.t('no')} </span> </p>` : ''}` +
        // `<p class='text-left pl-4 h6'> ${this.$i18n.t('main-form.transport-details.additional.notes')}: ${this.userAdditionalNotes}</p>`,
      )
    },
    // Get the text of selected items
    setTextFromPointsSelected () {
      this.localityValue = this.getOptionFromSelect('locality', this.parentLocalitySelected)
      this.localityValue = (this.localityValue && this.localityValue.text) || '-'
      this.scholasticYearValue = this.getOptionFromSelect('studentYear', this.scholasticYearSelected)
      this.scholasticYearValue = (this.scholasticYearValue && this.scholasticYearValue.text) || '-'
    },
    getOptionFromSelect (selectElement, value) {
      return this.$refs[selectElement].options.find(option => option.value === value)
    }
  },
  computed: {
    ...mapState({
      supplementary: 'supplementary',
      resubmissionAction: 'resubmissionAction'
    }),
    ...mapGetters({
      baseData: 'baseData',
      schoolLevelId: 'schoolLevelId'
    }),
    checkforSameEmail () {
      return this.parentEmail === this.parentConfirmEmail
    },
    isDisabled () {
      return this.resubmissionAction === null || this.resubmissionAction === 'reject'
    },
    isDisabledAndNotModify () {
      return this.isDisabled || this.resubmissionAction !== 'modify'
    },
    currentScholasticYearLevelInfo () {
      return this.supplementary.schoolLevels.find(yl => {
        return yl.schoolLevelLabel === this.scholasticYearSelected
      })
    },
    enableValidateButton () {
      const { parentTown, parentStreet, parentPostal, parentHouseNo, parentLocalitySelected } = this
      return {
        parentTown,
        parentStreet,
        parentPostal,
        parentHouseNo,
        parentLocalitySelected
      }
    },
    setTransportDetailsToDefault () {
      const { outOfLocality } = this
      return {
        outOfLocality
      }
    }
  },
  watch: {
    resubmissionAction: {
      handler () {
        this.localityDropdown = []
        this.scholasticYearOptions = []
        this.schoolNameOptions = []
        this.schoolMarker = []
        this.schoolBusStops = []
        this.pickupPointOptions = []
        this.isVerifiedAddress = true
        this.enableSchoolField = false
        if (this.resubmissionAction !== 'reject') {
          this.fillResubmissionInfo()
        }
        this.$nextTick(() => {
          this.ignoreTransportChanges = false
        })
      }
    },
    enableValidateButton: {
      handler () {
        if (this.resubmissionAction === 'modify') {
          this.isVerifiedAddress = false
          // this.scholasticYearSelected = null
          this.pickupPointSelected = null
          this.pickupPointOptions = []
          this.schoolNameSelect = null
          this.schoolNameOptions = []
          this.schoolMarker = []
          this.schoolBusStops = []
        }
      },
      deep: true
    },
    setTransportDetailsToDefault: {
      handler () {
        if (this.resubmissionAction !== null && !this.ignoreTransportChanges) {
          this.schoolMarker = []
          this.schoolNameOptions = []
          this.schoolBusStops = []
          this.pickupPointSelected = null
          this.pickupPointOptions = []
          this.schoolNameSelect = null
          this.selectStudentYear()
          // this.scholasticYearSelected = null
        }
      },
      deep: true
    }
    // scholasticYearSelected () {
    //   this.myJourney = this.isInYearRange('myJourney') ? this.myJourney : false
    //   this.scdp = this.isInYearRange('scdp') ? this.scdp : false
    // }
  },
  mounted () {
    this.fillResubmissionInfo()
    this.initialLoad()
    this.uniqueUUID = generateRandomUUID()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang='scss'>
.scrollable {
  scroll-margin-top: 10px;
}
.form {
  position: relative;
  &__back {
    &-btn {
      position: absolute;
      left: 10px;
      top: 10px;
    }
    &-icon {
      vertical-align: middle;
      font-size: 14px !important;
    }
  }
  .field-with-popover {
    display: block;
    white-space: nowrap;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .title{
    font-size: 22px;
  }
  .box-wrapper.padding-top-15{
    padding-top: 15px !important;
  }
  .terms-font-size{
    font-size:14px;
  }
  .input-pull-id-first, .input-pull-id-second {
    max-width: 41.666667%;
    margin-right: calc(100% - 41.666667%);
  }
  legend{
    color : rgb(80, 80, 80)
  }
  .confirm-address-text{
    cursor:pointer;
    font-style: italic;
    color:#4c4c4c;
    font-weight: 500;
      &:hover{
        text-decoration:underline;
      }
  }
  .btn.disabled, .btn:disabled {
    opacity: 0.5;
}
  .multiselect {
    min-height: 22px;
  }
  .form-multiselect{
    height: calc(1.5em + 0.5rem + 2px);
    display: block;
    padding: 4px 28px 4px 8px;
    border-radius: 0;
    border: 1px solid #ced4da;
    font-size: 0.875rem !important;
    line-height: 1.5;
    background: #fff;
    font-size: 14px;
    position: initial;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
   }
   .multiselect__option {
   white-space: normal;
  }

  .multiselect__element {
    background-color: #f7f6f6;
  }
   .multiselect-comp {
     position:inherit;
     max-height: 22px;
  }
  .multiselect__tags {
    min-height: 0px;
    padding: 0px;
    border: 0;
    background: none;
    font-size: 14px;
  }
  .multiselect__input, .multiselect__single{
    font-size: 0.875rem;
    color:#495057;
    padding:0;
    margin:0;
  }
  .multiselect__select{
    height:0;
    padding:0;
    top:22px;
  }
  .multiselect__content-wrapper{
    margin-top:6px;
    top: 30px;
    right: 0;
    width: calc(100vw - 1.4em * 2 - 20px - 16px);
    max-width: 400px;
    position: absolute;
    height: 300px;
  }
  .multiselect-isInvalid{
    border-color: #dc3545;
  }
  .multiselect-invalid-feddback{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    text-align: right;
    color: #dc3545;
  }
  .multiselect__placeholder{
    white-space: nowrap;
    color:#495057;
    padding-top:0
  }
  .custom-control.custom-control-inline.custom-radio:nth-child(1) {
    color: green;
  }
  .custom-control.custom-control-inline.custom-radio:nth-child(2) {
    color: orange;
  }
  @media (max-width: 575px) {
    .transport-details-margin{
      margin-bottom: 45px;
    }
    .input-pull-id-first, .input-pull-id-second {
      max-width: 100%;
      margin-right: 0px;
    }
    .text-align-left{
      text-align:left;
    }
    .b-form-padding{
      padding:0;
    }
    .terms-font-size{
      font-size:13px;
    }
  }
  @media (min-width: 576px) {
    .transport-details-margin{
      margin-bottom: 22px;
    }
    .input-pull-id-first, .input-pull-id-second {
      max-width: 100%;
    }
    .b-form-padding{
      padding:0 15px;
    }
    .text-align-left{
      text-align:left;
    }
  }
  @media (min-width: 768px) {
    .input-pull-id-first, .input-pull-id-second {
      max-width: 50%;
    }
    .b-form-padding{
      padding:0px 30px;
    }
  }
}
.printButton {
    color: #fff !important;
    background-color: #17a2b8!important;
    border-color: #17a2b8!important;
}
.initial-load-buttons {
    flex: 1 0 auto;
    margin: 5px 5px;
  }
</style>
